// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-dynamic-pages-blog-tsx": () => import("./../../../src/dynamicPages/blog.tsx" /* webpackChunkName: "component---src-dynamic-pages-blog-tsx" */),
  "component---src-dynamic-pages-faction-tsx": () => import("./../../../src/dynamicPages/faction.tsx" /* webpackChunkName: "component---src-dynamic-pages-faction-tsx" */),
  "component---src-dynamic-pages-post-tsx": () => import("./../../../src/dynamicPages/post.tsx" /* webpackChunkName: "component---src-dynamic-pages-post-tsx" */),
  "component---src-static-pages-404-tsx": () => import("./../../../src/staticPages/404.tsx" /* webpackChunkName: "component---src-static-pages-404-tsx" */),
  "component---src-static-pages-about-tsx": () => import("./../../../src/staticPages/about.tsx" /* webpackChunkName: "component---src-static-pages-about-tsx" */),
  "component---src-static-pages-download-tsx": () => import("./../../../src/staticPages/download.tsx" /* webpackChunkName: "component---src-static-pages-download-tsx" */),
  "component---src-static-pages-factions-tsx": () => import("./../../../src/staticPages/factions.tsx" /* webpackChunkName: "component---src-static-pages-factions-tsx" */),
  "component---src-static-pages-index-tsx": () => import("./../../../src/staticPages/index.tsx" /* webpackChunkName: "component---src-static-pages-index-tsx" */),
  "component---src-static-pages-terms-of-service-tsx": () => import("./../../../src/staticPages/terms-of-service.tsx" /* webpackChunkName: "component---src-static-pages-terms-of-service-tsx" */)
}

